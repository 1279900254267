import React from 'react'
import {Link} from "react-router-dom";
import SideBar_nav from "./sidebar"
import 'bootstrap/dist/css/bootstrap.css';
class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen : false
        }
    }
    componentDidMount (){
        this.setState({ isModalOpen: false });
    }
    handleModalOpen = event => {
        this.setState({ isModalOpen: true });
    }
    render () {
        return (
            <header>
                <SideBar_nav opendialog = {this.state.isModalOpen}/>
           <div className="navbar d-flex">

               <Link className="navbar-logo" to="/">
                   <img src="/image/logo.svg"/>
               </Link>
               <div className="navbar-title d-block">
                   <p className="navbar-title-first">Sygrin (Industry) USA Inc</p>
                   <p className="navbar-title-second">Everything for our customers</p>
               </div>
               <div className="navbar-menu d-md-flex">
                   <Link to="/about">About</Link>
                   <Link to="/application">Application</Link>
                   <Link to="/products">Products</Link>
                   <Link to="/pride">Pride</Link>
                   <a href="mailto:info@sygrin.com">Contact</a>
               </div>
               <div className="navbar_menu_icon" onClick={this.handleModalOpen}>
                   <img src="/image/navbar/menu-lines.svg"/>
               </div>
           </div>
            </header>
        );
    }
}
export default Navbar;
