import React from 'react'

class Pride extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="main_content">
            <div className="pride">
                <div className="d-lg-flex d-sm-block">
                    <img className="pride-image col-lg-5 col-sm-12"
                         src="https://s3.amazonaws.com/sygrin/pride-page-photo-1.png"/>
                    <p className="pride-top-text col-lg-5 col-sm-12">We are proud of our performance in the past 25 years. We supply
                        quality products, think for our
                        customers, offer competitive prices, do JIT delivery. Meantime, we know that we must strive to
                        continually improve the effectiveness of our quality management system. We pledge to
                        consistently manufacture our products that meet the specifications and quality expectations of
                        our customers.
                    </p>
                </div>
                <div className="pride-down-text col-12">
                    <p className="pride-down-text-title">Contact Us</p>
                    <p className="pride-down-text-content">Tel: 718-838-0818</p>
                    <p className="pride-down-text-content">sygrin@aol.com</p>
                    <p className="pride-down-text-content">info@sygrin.com</p>
                </div>
            </div>
            </div>
        );
    }
}

export default Pride;
