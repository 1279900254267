import React from 'react'
import {ProductsImage} from "./productsImage";

class Products extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="main_content">
                <div className="products d-lg-flex d-sm-block">
                    <div className="products_text col-lg-6 d-lg-block d-none">
                        <p className="products-first-text d-lg-block d-none">Materials</p>
                        <div className="d-lg-block d-none">
                            <p className="products-second-text">
                                Alumina Oxide (Al2O3): from 90% to 99.8%
                            </p>
                            <p className="products-second-text"> Glazed or unglazed, Moly-Mag metallized and Nickel
                                plating
                                ceramics (non-magnetic)</p>
                            <p className="products-second-text"> Zirconia Oxide (ZrO2)</p>
                            <p className="products-second-text"> Steatite: L-3, L-5</p>
                            <p className="products-second-text"> Silicon Carbide (SiC)</p>
                            <p className="products-second-text"> Silicon Nitride (Si3N4)</p>
                            <p className="products-second-text"> Boron Nitride (BN)</p>
                            <p className="products-second-text"> Titanium Dioxide (TiO2)</p>
                            <p className="products-second-text"> AlSiC (Aluminum Matrix SiC Particle Reinforced
                                Material)</p>
                            <p className="products-second-text"> Tungsten Carbide (WC)</p>
                            <p className="products-second-text"> Sapphire</p>
                            <p className="products-second-text"> Ruby</p>
                            <p className="products-second-text"> Others</p>
                        </div>
                    </div>
                    <p className="products-first-text d-sm-block d-lg-none d-md-none">Materials</p>
                    <div className="products-image-group">
                        {
                            ProductsImage.map((image, index) => (
                                <img className="gear-public-image-product" key={index} src={image}/>
                            ))
                        }
                        <div className="d-sm-block d-lg-none d-md-none products-sm-text">
                            <p className="products-second-text">
                                Alumina Oxide (Al2O3): from 90% to 99.8%
                            </p>
                            <p className="products-second-text"> Glazed or unglazed, Moly-Mag metallized and Nickel
                                plating
                                ceramics (non-magnetic)</p>
                            <p className="products-second-text"> Zirconia Oxide (ZrO2)</p>
                            <p className="products-second-text"> Steatite: L-3, L-5</p>
                            <p className="products-second-text"> Silicon Carbide (SiC)</p>
                            <p className="products-second-text"> Silicon Nitride (Si3N4)</p>
                            <p className="products-second-text"> Boron Nitride (BN)</p>
                            <p className="products-second-text"> Titanium Dioxide (TiO2)</p>
                            <p className="products-second-text"> AlSiC (Aluminum Matrix SiC Particle Reinforced
                                Material)</p>
                            <p className="products-second-text"> Tungsten Carbide (WC)</p>
                            <p className="products-second-text"> Sapphire</p>
                            <p className="products-second-text"> Ruby</p>
                            <p className="products-second-text"> Others</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Products;