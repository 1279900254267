import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import store from "./store/index";
import MainComponent from "./components/pages";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <MainComponent/>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
