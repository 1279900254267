import React from 'react'
import { Button} from 'react-bootstrap-buttons';
import {ProductsImage} from "../products/productsImage";
import Slideshow_image from "./slidershow_image";
class Main extends React.Component {
    constructor (props) {
        super (props);
    }
    render () {
        return (
            <div className="home">
                <div className="home-first-section">
                    <p>Only the best for our customers</p>
                    <Button  btnStyle="primary" className="home-section-start-btn">start</Button>
                </div>
                <div className="home-second-section">
                    <p className="home-second-section-title">Our Products</p>
                    <Slideshow_image sliderimage = {ProductsImage}/>
                </div>
            </div>
        );
    }
}
export default Main;