import React from 'react'
import {Button} from 'react-bootstrap-buttons';

class About extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

                <div className="about d-flex">
                    <div className="main_content">
                        <img className="about-image" src="https://s3.amazonaws.com/sygrin/about-page-photo-1.png"/>
                        <p className="about-first-text">Sygrin is devoted to developing world-class technology solutions
                            and
                            moving innovations to the market.</p>
                        <p className="about-second-text">Sygrin plays a leading role in development and production from
                            industrial ceramic materials (including glazed or unglazed, Moly-Mag metallization and
                            Nickel
                            plating on Alumina Oxide parts, non-magnetic), Zirconia, SiC, Si3N4, BN, TiO2, AlSiC,
                            Tungsten
                            Carbide, synthetic sapphire and ruby and other materials. These products are used in various
                            industrial productions.</p>
                    </div>
                </div>
        );
    }
}

export default About;