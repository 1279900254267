import React from 'react'
class Footer extends React.Component {
    constructor(props) {
        super(props);
    }
    render () {
        return (
            <div className="navbar footer d-lg-flex">
                <div className="navbar-logo d-lg-flex d-none">
                    <img src="/image/logo.svg"/>
                </div>
                <div className="navbar-title  d-lg-block d-none">
                    <p className="navbar-title-first">Sygrin (Industry) USA Inc</p>
                    <p className="navbar-title-second">Everything for our customers</p>
                </div>
                <div className="footer-menu d-lg-flex d-sm-block">
                    <p>© 2018 Sygrin. All right reserved</p>
                    <p className="d-lg-none d-md-none d-sm-flex">Legal</p>
                    <p className="d-lg-none d-md-none d-sm-flex" >Site map</p>
                </div>
            </div>
        );
    }
}
export default Footer;
