import React from 'react'
import routes from '../../config/routes';
import Navbar from "../common/navbar";
import Footer from "../common/footer";

class MainComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="content-wrapper">
                <Navbar/>

                {routes}

                <Footer/>
            </div>
        );
    }
}

export default MainComponent;