export const slider_style = {
    root: {
    position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",

    },
    sidebar: {
    zIndex: 7,
        position: "absolute",
        top: 0,
        bottom: 0,
        width: '100%',
        transition: "transform .3s ease-out",
        WebkitTransition: "-webkit-transform .3s ease-out",
        willChange: "transform",
        overflowY: "hide"
    },
    content: {
    position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: "hide",
        WebkitOverflowScrolling: "touch",
        transition: "left .3s ease-out, right .3s ease-out"
    },
    overlay: {
    zIndex: 6,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        visibility: "hidden",
        transition: "opacity .3s ease-out, visibility .3s ease-out",
        backgroundColor: "#000",


    },
    dragHandle: {
    zIndex: 1,
        position: "fixed",
        top: 0,
        bottom: 0
    }
};