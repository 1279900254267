import React from 'react'
import {ApplicationImages} from "./applicationImages";

class Application extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="main_content">
                <div className="application">
                    <div className="application_text">
                        <p className="application-first-text">Applications</p>
                        <p className="application-second-text d-lg-block d-none">Electronic components (such as capacitors, resistors,
                            feed
                            thru, electronic terminals, etc.),
                            lightning protection components, MRI and other medical devices, laser technology, optical
                            windows,
                            textile industry, Electronic IC packaging, circuit substrates, aerospace, AI electric
                            vehicle,
                            high-speed train, etc.
                        </p>
                    </div>
                    <div className="application-image-group">
                        {
                            ApplicationImages.map((image, index) => (
                                <img className="gear-public-image" key={index} src={image}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Application;