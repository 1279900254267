import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from "../components/pages/main"
import About from "../components/pages/about"
import Application from "../components/pages/applications";
import Products from "../components/pages/products";
import Pride from "../components/pages/pride";
export default (
    <Switch>
      <Route exact path="/" component={Main}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/application" component={Application}/>
        <Route exact path="/products" component={Products}/>
        <Route exact path="/pride" component={Pride}/>

    </Switch>
);
