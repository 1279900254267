import React from "react";
import Sidebar from "react-sidebar";
import {slider_style} from "./style";
import {Link} from "react-router-dom";

const mql = window.matchMedia(`(min-width: 800px)`);

class SideBar_nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarDocked: mql.matches,
            sidebarOpen: false
        };
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    componentWillReceiveProps(next_props) {
        this.onSetSidebarOpen(next_props.opendialog);
    }

    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    onSetSidebarOpen(open) {
        this.setState({sidebarOpen: open});
    }

    mediaQueryChanged() {
        this.setState({sidebarDocked: mql.matches, sidebarOpen: false});
    }

    render() {
        return (
            <div>
                <Sidebar
                    sidebar={
                        <div className="sm_nav_parent">
                            <div className="nav_close_btn" onClick={() => this.onSetSidebarOpen(false)}/>
                            <div className="mobile_nav_menu d-block">
                                <Link className="mobile_nav_text" to='/about'>
                                    <p onClick={() => this.onSetSidebarOpen(false)}>About</p>
                                </Link>
                                <Link className="mobile_nav_text" to='/application'>
                                    <p onClick={() => this.onSetSidebarOpen(false)}>Application</p>
                                </Link>
                                <Link className="mobile_nav_text" to='/products'>
                                    <p onClick={() => this.onSetSidebarOpen(false)}>Products</p>
                                </Link>
                                <Link className="mobile_nav_text" to='/Pride'>
                                    <p onClick={() => this.onSetSidebarOpen(false)}>Pride</p>
                                </Link>
                                <a className="mobile_nav_text" href="mailto:info@sygrin.com">Contact</a>
                                <div className="footer-menu d-lg-flex d-sm-block slider-footer">
                                    <p className="col-12">© 2018 Sygrin. All right reserved</p>
                                    <div className="d-flex col-24">
                                        <p className="d-lg-none d-md-none d-sm-flex col-5">Legal</p>
                                        <p className="d-lg-none d-md-none d-sm-flex col-5">Site map</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                    open={this.state.sidebarOpen}
                    onSetOpen={this.onSetSidebarOpen}
                    styles={slider_style}
                    pullRight={true}
                >
                </Sidebar>

            </div>
        );
    }
}

export default SideBar_nav;